
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import SidebarPurchase from '@/components/sidebar-purchase.vue'; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import UserManagementService from "@/services/user-management";
import productService from '@/services/product-service';
import projectService from "@/services/project-service";
import purchaseService from '@/services/purchase-services'
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils";

@Component({
  components: {
    Header,
    SidebarPurchase,
    PopUp
  },
})
export default class PurchaseEnquires extends Vue {
  loading = true;
  trans: any = {}
  requisition_no = ""
  public payload: any = {}
  public selected_requests: any = {}
  transaction_options: any = {}
  document_file=null;
  items: any = [];
  product_opt: any = []
  selected_projects: any = []
  selected_employees: any = []
  currency_opt: any = []
  other_info: any = {};
  error:any=""
  // For Modal
  modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";
    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalType = "";
        this.modalSimpleMessage = ""
    }
    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }
  addRow() {
    this.items.push({ items_master: {}, desc: '', qty: 0, unit: '', amount: '',brand: '', project: {},item_type:'', comment: '' })
  }
  deleteRow(index: any) {
    this.items.splice(index, 1);
  }
  clearRow(index: any) {
    const item = this.items[index];
    item.items_master= {}
    item.desc = ''
    item.qty = 0
    item.unit = ''
    item.amount=''
    item.brand = ''
    item.project = {}
    item.item_type=''
    item.comment = ''
  }
  mounted() {
        this.retrieve()
    }
    retrieve() {
      this.loading = true;
        purchaseService.getAllCurencies().then((resp) => {
            this.currency_opt = resp.data
            this.loading = false;
        }).catch((e) => {
          console.log(e);
        });
    }
    getRate(data: any) {
      this.payload['transaction_rate'] = data.rate
      this.payload['transaction_id'] = data.id
  }
  save() {
    this.payload.requisition_no = this.selected_requests.request_no
    this.payload['items'] = this.items
    this.payload['other_info'] = this.other_info[0]
    purchaseService.createEnquiryRequest(this.payload).then((resp) => {
      if (resp.status === 201) {
        purchaseService.uploadEnquiryDoc(this.document_file, resp.data.result.id).then((response) => {
            this.showModal("Succesfully created", [], "success");
            this.reset();
          })
    }
    }).catch((e) => {
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
  }
  index = -1
  search_product_item(title: any, index: any) {
    this.index = index;
    if (title.length > 2) {
      productService
        .getByTitle(title)
        .then((response) => {
          this.product_opt = response.data.result;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.product_opt = [];
    }
  }
  get_product_item(data: any) {
    this.items[this.index].items_master = data;
    this.product_opt = [];
    this.index = -1
  }
  emp_index = 0;
  search_employee(title: any, index: any) {
    this.emp_index = index;
    if (title.length > 2) {
      UserManagementService.get_Employee_by_Title(title)
        .then((res) => {
          this.selected_employees = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.selected_employees = [];
    }
  }
  get_data(data: any) {
    this.payload.executive = data.id;
    this.payload.executive_title = data.title;

    this.selected_employees = [];
  }
  type = ""
  project_index = 0
  searchProject(title: any, index: any, type: any) {
    this.type = type
    this.project_index = index
    if (title.length == 0) {
      this.selected_projects = [];
      return
    }
    projectService.getByTitle(title).then((response) => {
      this.selected_projects = response.data.result
    })

  }
  get_project_data(data: any, type: any) {
    if (type == 'project') {
      this.items[this.project_index].project = data
    }
    else if (type == 'cost-center') {
      this.payload.cost_center_title = data.title
      this.payload.cost_center = data.id
    }
    this.selected_projects = [];
  }
  getRequest(requisition_no: string) {
    this.items = []
    purchaseService.getPurchaseRequest(requisition_no).then((resp) => {
      this.selected_requests = resp.data
      this.payload.cost_center_title = this.selected_requests.cost_center_title
      this.payload.cost_center = this.selected_requests.cost_center
      const transaction = {
        id: this.selected_requests.transaction,
        title: this.selected_requests.transaction_title,
        rate: this.selected_requests.transaction_rate,
      }
      this.payload.transaction = transaction      
      this.other_info = resp.data.purchase_info
      resp.data.items.forEach((element: any) => {
        let array: any = {}
        array['items_master'] = { 'id': element['product'], 'title': element['product_title'], "sku": element['product_sku'] }
        array['project'] = { 'id': element['project'], 'title': element['project_title'] }
        array['project_title'] = element['project_title']
        array['qty'] = element['qty']
        array['unit'] = element['product_no_of_pieces']
        array['desc'] = element['desc']
        array['item_type'] = element['item_type']
        array['comment'] = element['comment']
        this.items.push(array)
      });
    }).catch((e) => {
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
  }
  reset(){
    this.payload = {};
    this.selected_requests={}
    this.error="";
    this.other_info=[];
    this.requisition_no=""
    this.document_file = null;
    this.clearRow(0);
    for (let i = this.items.length - 1; i > 0; i--) {
      this.deleteRow(i);
    }
  }
  downloadDocument(url: string) {
    const anchor = document.createElement("a");
    const fileUrl = process.env.VUE_APP_SERVER + url;

    // Check if the URL is valid and the file exists
    fetch(fileUrl)
      .then(response => {
        if (response.status === 200) {
          // The file exists, so we can proceed with the download
          anchor.href = fileUrl;
          anchor.download = "downloaded_file_name"; // You can specify the name of the downloaded file here
          anchor.target = "_blank";

          document.body.appendChild(anchor);
          anchor.click();

          document.body.removeChild(anchor);
          this.showModal("File Downloaded",[], "success")
        } else {
          this.showModal('File not found or URL is not valid.',[], "error")
          // Handle the case where the file does not exist
        }
      })
      .catch(error => {
        // Handle other network-related errors
        this.showModal("An error occurred while fetching the file:",[], "error");
      });
  }
}
